.menu-item {
  padding: 8px 6px;
  padding-right: 22px;

  /* transition: background-color 0.2s; */
  
  background-color: #ffffff;
  
  font-size: 16px;
  text-align: right;
  
  color: #666;

  user-select: none;

  border-bottom: 1px solid #c9c9c9;
}
@media only screen and (max-width: 768px) {
  .menu-item {
    padding: 18px 6px;
    padding-right: 22px;
  }
}

.menu-item.clickable {
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item.active {
  background-color: #e2e1e1;
  cursor: initial;
}

.menu-item-value {
  font-weight: 600;
}

.menu-item.menu-list-label {
  font-size: 12px;
  font-weight: 600;

  background-color: #f9f9f9;

  padding-top: 16px;
}
@media only screen and (max-width: 768px) {
  .menu-item.menu-list-label {
    padding: 11px 6px;
    padding-right: 22px;
    padding-top: 20px;
  }
}

.menu-item.is-bot {
  padding: 0;
}

.menu-item .bot-list-item {
  flex-direction: row-reverse;
  border-bottom: none;
}

.menu-item .bot-list-item .item-info {
  left: 10px;
  right: 68px;
}

/* Dark Mode */
.dark-mode .menu-item {
  background-color: #1d1d1d;
  border-color: #2d2d2d;
  color: #a6a6a6;
}

.dark-mode .menu-item:hover {
  background-color: #222;
}
.dark-mode .menu-item.active {
  background-color: #2f2f2f;
}

.dark-mode .menu-item.menu-list-label {
  background-color: #171717;
}

.dark-mode .menu-item.is-bot {
  background-color: #141414;
}