.conversation-list-item {
  padding: 8px 6px;
  padding-left: 34px;

  cursor: pointer;
  transition: background-color 0.2s;
  
  background-color: #ffffff;
  
  font-size: 16px;
  color: #666;

  user-select: none;

  border-bottom: 1px solid #c9c9c9;
}
@media only screen and (max-width: 768px) {
  .conversation-list-item {
    padding: 16px 6px;
    padding-left: 45px;
  }
}

.conversation-list-item:hover {
  background-color: #f0f0f0;
}

.conversation-list-item.active {
  background-color: #e2e1e1;
  cursor: initial;
}

/* Dark Mode */
.dark-mode .conversation-list-item {
  background-color: #1d1d1d;
  border-color: #2d2d2d;
  color: #a6a6a6;
}
.dark-mode .conversation-list-item:hover {
  background-color: #222;
}
.dark-mode .conversation-list-item.active {
  background-color: #2f2f2f;
}