.menu-view {
  position: absolute;
  overflow-y: auto;
  
  z-index: 70; /* behind header, in front of bot-list */
  
  width: 100%;
  
  top: 62px; /* set to height of [.header] - TODO: use better approach e.g. variable */
  bottom: 0;
  left: 0;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.chat-window.large-header .menu-view {
  top: 90px; /* set to height of [.header] - TODO: use better approach e.g. variable */
}