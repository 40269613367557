/* App.css */
html, body {
  margin: 0;
  height: 100%;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.chat-window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 8px;
}

.debug-text {
  position: fixed;
  font-size: 11px;
  background-color: rgba(255, 255, 255, 0.33);
  top: 95px;
  left: 0;
  right: 0;
  bottom: 0;
  color: #ff0000;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
  white-space: pre-line;
  word-wrap: break-word;
  text-align: right;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media only screen and (max-width: 768px) {
  .debug-text {
    top: 118px;
    pointer-events: none;
  }
}

/* Dark Mode */
.chat-window.dark-mode {
  background-color: #111;
  color: #fff;
}