/* MessageList.css */
.messages {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 60px 8% 16px;
  overflow-y: auto;
}

.messages.no-user-messages {
  padding-bottom: 32px;
}

.messages-inner {
  display: flex;
  flex-direction: column;
}

.messages-inner-spacer {
  flex-grow: 1;
}

.messages-end-container {
  position: relative;
}
.messages-end-target {
  position: absolute;
  top: 16px; /* moved down by same amount as bottom padding of messages, so view scrolls to bottom */
}

.message {
  max-width: 70%;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 16px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@keyframes fade-in-from-left {
  from {
    opacity: 0;
    transform: translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-from-below {
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-hidden-label {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.message.user {
  align-self: flex-end;
  background-color: #6795ed;
  color: #ffffff;
}
.message.user.appended {
  opacity: 0;
  animation-name: fade-in-from-below;
  animation-duration: 0.25s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}

.message.assistant {
  align-self: flex-start;
  background-color: #e5e5ea;
  color: #333;
}
.message.assistant.appended {
  opacity: 0;
  animation-name: fade-in-from-left;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.message.assistant.message-pending {
  position: relative;
}
.message.assistant.message-pending::after {
  content: "\00a0"; /* Insert a non breaking space to preserve message bubble height */
}
.message-loading-graphic-container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}
.message-loading-graphic {
  display: block;
  width: 100%;
  height: 100%;
  fill: #fff;
  
  animation: rotation 1s infinite steps(8);
}

.message.error {
  align-self: center;
  
  background-color: #f5f5f5;
  font-size: 12px;
  color: #888888;

  white-space: pre-line;
  text-align: center;
  
  opacity: 0;
  animation-name: fade-in-from-left;
  animation-duration: 0.25s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.messages-load-info { 
  position: absolute;
  left: 48px;
  top: 3px;

  font-size: 13px;

  display: flex;
  align-items: center;

  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 4s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.message-load-info-icon {
  width: 20px;
  height: 20px;
  display: block;
  fill: #bbb;
}
.message-load-info-text {
  padding: 6px 8px;
  color: #888;
  white-space: nowrap;

  user-select: none;
}


.message.hasMedia .message-text {
  padding-top: 4px;
  padding-bottom: 4px;
}

.message-media {
  padding-top: 8px;
  padding-bottom: 8px;
}
.message-media-image {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-color: #000;
  
  display: inline-block;

  display: flex;
  align-items: center;
  justify-content: center;
}
  .message-media-image, .message-media-image img {
  max-width: 100%;
  min-width: 25vw;
  min-height: 25vw;
}

.message-media-image .message-loading-graphic-container {
  display: block;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
}

.message-media-image-error {                     
  color: #a9a9a9;
  font-size: 12px;
  text-align: center;
  max-width: 82%;
  word-wrap: break-word;
}


/* Dark Mode */
.dark-mode .message.user {
  background-color: #21417b;
  color: #dddddd;
}
.dark-mode .message.assistant {
  background-color: #222222;
  color: #cacaca;
}
.dark-mode .message.error {
  background-color: #261817;
  color: #9c9c9c;
}
.dark-mode .message-loading-graphic {
  fill: #707070;
}
.dark-mode .message-load-info-icon {
  fill: #3a3a3a;
}
.dark-mode .message-load-info-text {
  color: #5e5e5e;
}