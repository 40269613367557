.bot-edit-view {
  position: absolute;
  bottom: 0;
  width: 100%;
  
  top: 62px; /* set to height of [.header] - TODO: use better approach e.g. variable */

  z-index: 120; /* in front of  header */

  overflow-y: auto;

  background-color: white;
}

.bot-edit-view.full-screen {
  top: 0;
  z-index: 120; /* in front of  header */
}

.bot-edit-view-container {
  position: relative;
}

.bot-edit-view .header {
  position: relative;

  height: 34px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 768px) {
  .bot-edit-view .header {
    height: 55px;
  }
}

.bot-edit-view .header .left-controls {
  display: flex;
  align-items: center;
  flex: 1;
}
.bot-edit-view .header .right-controls {
  display: flex;
  align-items: center;
}

.bot-edit-view .header .button {
  display: flex;
  align-items: center;
  
  background-color: transparent;
  border: none;
  outline: none;
  
  padding: 8px 8px;

  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .bot-edit-view .header .button {
    padding: 18px 8px;
  }
}

.bot-edit-view .header .button-label {
  font-weight: 600;
  font-size: 12px;
  color: #666;

  white-space: nowrap;
  padding: 3px 6px;

  user-select: none;
}

.bot-edit-view .header .button-icon {
  width: 24px;
  height: 24px;
  fill: #666;
}

.bot-edit-view .header .button-label {
  transform: translate(0, -1px);
}


.bot-edit-view-content {
  padding-top: 8px;
  padding-bottom: 32px;
}

.bot-edit-view .field {
  padding: 8px 22px;
}

.bot-edit-view .field .label {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 5px;
  user-select: none;
}

.bot-edit-view .field .label .note {
  font-weight: initial;
  opacity: 0.5;
}

.bot-edit-view .field input[type="text"], .bot-edit-view .field textarea {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  flex: 1;

  padding: 5px 5px;

  width: 100%;
  box-sizing: border-box;
  
  border: 1px solid #666;
  outline: none;

  color: #333;
}
.bot-edit-view textarea {
  height: initial;
  
  scrollbar-width: none; /* Hide text area scrollbar for Firefox */
  overflow: -moz-scrollbars-none; /* Hide text area scrollbar for older versions of Firefox */

  /* resize: none; */
  min-height: 20px; /* Allow resize but set minimum height of the text area */
}
/* Hide text area scrollbar for Chrome, Edge, & Safari */
.bot-edit-view textarea::-webkit-scrollbar {
  display: none;
}

.bot-edit-view .field .profile-image-wrapper {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.bot-edit-view .field .profile-image-wrapper img {
  user-select: none;
}

.bot-edit-view .field .checkbox {
  display: flex;
  align-items: center;
}
.bot-edit-view .field .checkbox, .bot-edit-view .field .checkbox input, .bot-edit-view .field .checkbox label {
  cursor: pointer;
}
.bot-edit-view .field .checkbox .label {
  padding-bottom: initial;
  padding-left: 8px;
  padding-right: 24px;
  min-width: 50%;
}

.bot-edit-view .field .checkbox.disabled {
  pointer-events: none;
}
.bot-edit-view .field .checkbox.disabled .label {
  opacity: 0.5;
}

.loading-overlay {
  position: absolute;
  background-color: #00000080;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
}

.loading-overlay .message-loading-graphic-container {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
}


/* Dark Mode */
.dark-mode .bot-edit-view {
  background-color: #141414;
}

.dark-mode .bot-edit-view .header {
  background-color: #191919;
  border-color: #282828;
}
.dark-mode .bot-edit-view .header .button-label {
  color: #afafaf;
}

.dark-mode .field .label {
  color: #ccc;
}

.dark-mode .field input[type="text"], .dark-mode .field textarea {
  color: #ddd;
  background: #0e0e0e;
}