.conversation-view {
  position: absolute;
  overflow-y: auto;
  
  z-index: 40; /* behind conversation-bar-header, in front of debug-text */
  
  width: 100%;
  
  top: 95px; /* set to height of [.header] + [.conversation-bar-header] - 1px to underlap - TODO: use better approach e.g. variables */
  bottom: 0;
  left: 0;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.chat-window.large-header .conversation-view {
  top: 123px;  /* set to height of [.chat-window.large-header .header] + [.conversation-bar-header] - 1px to underlap - TODO: use better approach e.g. variables */
}

@media only screen and (max-width: 768px) {
  .conversation-view {
    top: 116px;  /* set to height of [.header] + (max-width: 768px)[.conversation-bar-header] - 1px to underlap - TODO: use better approach e.g. variables */
  }
  .chat-window.large-header .conversation-view {
    top: 144px;  /* set to height of [.chat-window.large-header .header] + (max-width: 768px)[.conversation-bar-header] - 1px to underlap - TODO: use better approach e.g. variables */
  }
}