.bot-list-item {
  display: flex;
  align-items: center;

  padding: 8px 16px;

  cursor: pointer;

  border-bottom: 1px solid #eee;
}

.item-info {
  position: absolute;
  left: 68px;
  right: 10px;
}
.item-info-annotation {
  font-style: italic;
  /* font-weight: 600; */
}

.item-heading {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  user-select: none;
}

.item-heading-element {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-description {
  font-size: 12px;
  color: #444;
  white-space: nowrap;
  user-select: none;
}

.item-description-element {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bot-list-item:hover {
  background-color: #f5f5f5;
}

.bot-list-item.active {
  background-color: #e5e1e1;
}



/* Dark Mode */
.dark-mode .bot-list-item {
  border-color: #2c2c2c;
}
.dark-mode .bot-list-item:hover {
  background-color: #1c1c1c;
}
.dark-mode .bot-list-item.active {
  background-color: #272727;
  /* background-color: #3d3c3c; */
}
.dark-mode .item-heading {
  color: #e2e2e2;
}
.dark-mode .item-description {
  color: #8f8f8f;
}