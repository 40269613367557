.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 100; /* in front of main content, behind full screen elements like create bot screen (BotEditView) */

  height: 62px; /* referenced by .conversation-view and .bots-view - TODO: use better approach e.g. variable */
  width: 100%;
  box-sizing: border-box;

  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

.header-left {
  flex: 1;
  
  display: flex;
  align-items: center;

  height: 100%;
}

.header-left.clickable {
  cursor: pointer;
}

.profile-header {
  display: flex;
  align-items: center;
  width: 100%;

  margin-left: 36px;
  padding: 11px 0px;
}
.profile-header.bot-list-shown {
  opacity: 0.75;
}
.profile-header-content {
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
}

.header-info {
  position: absolute;
  left: 54px;
  right: 0;
}

.header-heading {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  user-select: none;
}

.header-heading-element {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-status {
  font-size: 12px;
  color: #969696;
  white-space: nowrap;
  user-select: none;
}

.header-description {
  font-size: 12px;
  color: #999;
  white-space: nowrap;
  user-select: none;
}

.header-description-element {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.online-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;

  bottom: -3px;
  right: -3px;

  background-color: #00dd00;
  border: 3px solid #fff;
}

.header-right {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.header .header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  border: none;
  outline: none;
  padding: 10px 8px;

  cursor: pointer;
  user-select: none;
}

.header .button-icon {
  width: 30px;
  height: 30px;
  fill: #222;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
}

.header-button .header-button-icon {
  width: 55%;
  height: 55%;
  fill: #222;
}


.header .menu-button .menu-icon {
  width: 24px;
  height: 24px;
}

.header .new-chat-button .button-icon-container {
  border: 2px solid #222;
  border-radius: 50%;
}
.header .new-chat-button .button-icon-container .plus-icon {
  margin-left: 1px;
}

.header .button-label {
  font-weight: 600;
  font-size: 12px;
  color: #222;

  white-space: nowrap;
  padding: 6px 6px;
}


.header .dropdown-button {
  padding: 15px 4px 12px 11px;
  position: absolute;
  left: 0;
}
.header .dropdown-button .button-icon {
  width: 14px;
  height: 14px;

  margin-top: -3px;

  transform: translate(0, 0.5px);
  transition: transform 0.1s ease-in-out;
}
.header .dropdown-button.dropdown-open .button-icon {
  transform: translate(0, 0.5px) rotate(-90deg);
}

/* Large Header */

.chat-window.large-header .header {
  height: 90px; /* referenced by .conversation-view and .bots-view - TODO: use better approach e.g. variable */
}
.chat-window.large-header .header-heading {
  font-size: 22px;
}
.chat-window.large-header .header-description {
  font-size: 14px;
}
.chat-window.large-header .header .dropdown-button {
  padding: 15px 8px 12px 13px;
}

.chat-window.large-header .profile-image-wrapper {
  width: 60px;
  height: 60px;
  margin-left: 2px;
}
.chat-window.large-header .profile-image-container {
  width: 60px;
  height: 60px;
}
.chat-window.large-header .header-info {
  padding-left: 18px;
  padding-bottom: 2px;
}


/* Dark Mode */
.dark-mode .header {
  background-color: #222222;
  border-color: #2b2b2b;
}
.dark-mode .online-dot {
  border-color: #282828;
}
.dark-mode .header-description {
  color: #d7d7d7;
}
.dark-mode .header .button-icon {
  fill: #eeeeee;
}