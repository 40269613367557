/* MessageInput.css */
.input-area {
  position: relative;

  display: flex;
  align-items: flex-end;

  padding: 10px 8% 30px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5ea;
  
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.05);
}

.input-area .text-region {
  flex: 1;
  display: flex;
  align-items: flex-end;

  background: #fff;
  border-radius: 17px;
}

.input-field, .text-area {
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  flex: 1;
  height: 24px;

  padding: 8px 16px;
  padding-right: 0;
  
  background-color: transparent;
  background: none;
  border: none;
  outline: none;

  color: #333;
}
.disable-selection .input-field, .disable-selection .text-area  {
  user-select: none;
}

.text-area {
  height: initial;
  
  scrollbar-width: none; /* Hide text area scrollbar for Firefox */
  overflow: -moz-scrollbars-none; /* Hide text area scrollbar for older versions of Firefox */

  /* resize: none; */
  min-height: 20px; /* Allow resize but set minimum height of the text area */
}
/* Hide text area scrollbar for Chrome, Edge, & Safari */
.text-area::-webkit-scrollbar {
  display: none;
}

.input-area .send-button {
  width: 40px;
  height: 40px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-right: 3px;

  background-color: transparent;
  border: none;
  outline: none;

  opacity: 0.25;
}

.input-area .send-button.active {
  cursor: pointer;
  
  opacity: 1;
}

.input-area .send-button .button-icon {
  width: 75%;
  height: 75%;

  fill: #666;
}

.input-area .send-button.active .button-icon {
  fill: #6795ed;
}

.text-area-button {
  width: 8%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  cursor: pointer;
  
  background-color: transparent;
  border: none;
  outline: none;
  
  padding-bottom: 21px;
}

.text-area-button-icon-region {
  width: 10px;
  float: right;
  margin-right: 1px;

  background-color: transparent;
  padding: 4px;
  border-radius: 4px;
}
.text-area-button.active .text-area-button-icon-region {
  background-color: #ddd;
}

.text-area-button-icon {
  width: 100%;
  display: block;
  fill: #999;
}

.logging-warning {
  position: absolute;
  display: flex;
  
  align-items: center;

  left: 0;
  right: 0;
  bottom: 0;

  height: 36px;

  padding: 0 8%;
  margin-left: 15px;

  color: #888;

  font-size: 12px;
  line-height: 1em;

  user-select: none;
}
@media only screen and (max-width: 768px) {
  .logging-warning {
    justify-content: center;
    text-align: center;

    padding: 0 9px;

    font-size: 10px;
    margin-left: 0;
  }
}


/* Dark Mode */
.dark-mode .input-area {
  background-color: #191919;
  border-color: #171717;
}
.dark-mode .input-field, .dark-mode .text-area {
  color: #b1b1b1;
}
.dark-mode .input-area .text-region {
  background: #0e0e0e;
}
.dark-mode .text-area-button-icon {
  fill: #454444;
}
.dark-mode .text-area-button.active .text-area-button-icon-region {
  background-color: #262626;
}
.dark-mode .text-area-button.active .text-area-button-icon {
  fill: #6e6e6e;
}
.dark-mode .input-area .send-button.active .button-icon {
  fill: #a8a8a8;
}
.dark-mode .logging-warning {
  color: #656565;
}