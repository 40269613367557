.conversation-bar-header {
  position: relative;

  z-index: 50; /* behind header, in front of conversation-view */

  height: 34px; /* referenced by .conversation-view - TODO: use better approach e.g. variable */

  display: none;
  align-items: center;
  justify-content: space-between;

  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 768px) {
  .conversation-bar-header {
    height: 55px; /* referenced by .conversation-view - TODO: use better approach e.g. variable */
  }
}
.conversation-bar-header.shown {
  display: flex;
}

.conversation-label {
  padding: 4px 6px;
  padding-left: 34px;

  margin-top: -5px;

  padding-bottom: 3px;
  font-weight: 500;
  font-size: 16px;
  color: #666;
}
@media only screen and (max-width: 768px) {
  .conversation-label {
    padding-left: 45px;
    margin-top: -2px;
  }
}

.conversation-label.new-chat {
  opacity: 0.5;
}

.conversation-bar .button {
  display: flex;
  align-items: center;
  
  background-color: transparent;
  border: none;
  outline: none;
  
  padding: 8px 8px;

  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .conversation-bar .button {
    padding: 18px 8px;
  }
}


.conversation-bar .button-label {
  font-weight: 600;
  font-size: 12px;
  color: #666;

  white-space: nowrap;
  padding: 3px 6px;

  user-select: none;
}

.conversation-bar .button-icon {
  width: 24px;
  height: 24px;
  fill: #666;
}

.conversation-bar .button-label {
  transform: translate(0, -1px);
}

.conversation-bar .left-controls {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 16px 0;
}
.conversation-bar .left-controls.active {
  cursor: pointer;
}
.conversation-bar .left-controls.conversation-list-shown {
  opacity: 0.75;
}

.conversation-bar .right-controls {
  display: flex;
  align-items: center;
}

.conversation-bar .dropdown-button {
  padding: 12px 4px 12px 11px;
  position: absolute;
  left: 0;
}
@media only screen and (max-width: 768px) {
  .conversation-bar .dropdown-button {
    padding: 12px 11px 12px 18px;
  }
}
.conversation-bar .dropdown-button .button-icon {
  width: 14px;
  height: 14px;

  margin-top: -3px;

  transform: translate(0, 0.5px);
  transition: transform 0.1s ease-in-out;
}
.conversation-bar .dropdown-button.dropdown-open .button-icon {
  transform: translate(0, 0.5px) rotate(-90deg);
}


.conversation-bar .new-chat-button {
  margin-right: 10px;
}
/* For circle around plus */
/* .conversation-bar .new-chat-button .button-icon-container {
  width: 24px;
  height: 24px;

  border: 2px solid #222;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  margin-left: 4px;
} */
.conversation-bar .new-chat-button .button-icon-container .button-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;

  /* For circle around plus */
  /* width: 16px;
  height: 16px;
  transform: translate(0.6px, -0.3px); */
}

.conversation-bar .button.options-button {
  padding: 8px 8px;
}
.conversation-bar .button.options-button .button-icon {
  width: 16px;
  height: 16px;
}

/* Dark Mode */
.dark-mode .conversation-bar-header {
  background-color: #191919;
  border-color: #282828;
}
.dark-mode .conversation-label {
  color: #bbbbbb;
}
.dark-mode .conversation-bar .button-label {
  color: #afafaf;
}
.dark-mode .conversation-bar .button-icon {
  fill: #afafaf;
}