.profile-image-wrapper {
  width: 40px;
  height: 40px;

  position: relative;
}

.profile-image-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;

  overflow: hidden;

  background-color: black;
  /* border: 1px solid #cccccc; */
}

.profile-image {
  width: 100%;
  height: 100%;
  user-select: none;
}