.bots-view {
  position: absolute;
  bottom: 0;
  width: 100%;
  
  top: 62px; /* set to height of [.header] - TODO: use better approach e.g. variable */

  z-index: 60; /* behind header, in front of conversation-bar */

  overflow-y: auto;

  background-color: white;
}

.chat-window.large-header .bots-view {
  top: 90px; /* set to height of [.header] - TODO: use better approach e.g. variable */
}


/* Dark Mode */
.dark-mode .bots-view {
  background-color: #141414;
}